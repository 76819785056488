import React from 'react';
import BrandingVariables from 'app/components/layouts/BrandingVariables';

import './styles/header.scss';
import './styles/layout.scss';
import './styles/accessibility.scss';
import './styles/animations.scss';

export default function EnhancedStyles(props) {
  return (
    <>
      <BrandingVariables />
      <div {...props} />
    </>
  );
}
